
.popup-msg {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    text-align: center;
    background-color: #F4F7FF;
}
.popup-icon {
    min-height: 30%;
}
.popup-msg .popup-text {
    margin: 10px;

}

.popup-btn {
    min-width:80px ;
    padding: 0px;
}
