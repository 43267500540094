.tableStyle {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
    /* Adds space between rows */
}

.leftCell {
    width: 150px;
    text-align: left;
    vertical-align: top;
    padding-right: 15px;

}

.middleCell {
    width: 20px;
}

.rightCell {
    width: 450px;
    text-align: right;
    vertical-align: top;
    background-color: rgba(215, 227, 248, 0.2);
    border-radius: 10px;
    margin-right: 10px;
}

.Cell1 {
    width: 100%;
    text-align: right;
    vertical-align: top;
    background-color: rgba(215, 227, 248, 0.2);
    border-radius: 10px;
    padding: 3px;
    height: 100%;
    border: 0px;
}

  input textarea{
    border: none !important;
}



