.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;

    transition: width 0.3s;
    width: 30px;
    /* Other styles */
    transition: width 0.3s;
    height: 100%;

    border-right: 1px solid #DEE1EB;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar.expanded {
    min-width: 130px;
}

.sidebar
.nav-link,
.bottom-links .icon {
    height: 35px;
    margin: 15px 0px;

    /* Additional styles for links */
}

.sidebar .icon {
    display: flex;
    align-items: center;
    margin: 5px 0px;

}

.bottom-div {
    margin-bottom: 40px;

}

.first-item {
    height: 100px;
    display: flex;
    align-items: center;
    /* This will center the logo vertically */
}

.expand-div {

    margin-left: 5px;
    padding: 0px 3px;
    display: flex;
    align-items: center;
    /* justify-content: center; */

    text-align: right;
    width: 100%;
    height: 35px;
    flex-shrink: 0;

    /* font-family: 'Noto Sans Hebrew'; */

    line-height: normal;
}


.icon-hover-effect {
    color: #9100C3;
    border-radius: 15px;
    background: #FAEBFF;
    margin: 5px 0px;
    padding: 2px 0px
}

.textspan {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.icon-selected {

    color: #9100C3;
    border-radius: 8px;
    background: #FAEBFF;
    /* Example style */
}

.placeholderdiv {

    height: 15vh;
}