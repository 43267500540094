.container-unit {
    display: flex;
}

.subtitle {
    width: 25%;
}

.wrap-subtitle-row {
    width: 100%;

}

.subtitle-row {
    text-align:unset;
    padding-right: 10px;
    display: inline-block;
    min-height: 37.39px;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    /* background-color: hsl(226, 100%, 81%, 0.2); */
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}


.unset-felx {
    position: inline-block;
}

.relative-style {
    position: relative;
}

.input-file {
    padding: 0;

}

.unborder {
    border: unset;
}

.subtitle-icon {
    position: absolute;
    left: -30px;
}

/* .input-file-style {
    display: inline-block;
    height: 0px;
    width: 0px;

} */

/* .input-file-style::before {
    background-color: hsl(226, 100%, 81%, 0.2);
    display: inline-block;
    height: 20px;
    width: 20px;

} */
#input-file-style {
    display: inline-block;
    height: 0px;
    width: 0px;
}