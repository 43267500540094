.breadcrumb-link {
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  color: #007bff;
  /* Adjust the color to fit your theme */
  font-size: 16px;
  /* Uniform font size */
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.breadcrumb-link:hover {
  color: #0056b3;
  /* Slightly darker shade on hover */
  /* transform: scale(1.05); */
  /* Slightly enlarge text on hover */
  background-color: rgba(0, 123, 255, 0.1);
  /* Subtle background color */

  border-radius: 5px;
  /* Rounded corners for the background */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  /* Subtle shadow effect */
}

.linkcontainer {
  font-family: "Arial", sans-serif;
  color: blueviolet;
  margin-right: 50px;
  display: flex;
  /* flex-direction: row-reverse; */
  /* justify-content: flex-end; */
}

.separator {
  padding: 0px 15px;
}
