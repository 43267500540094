
.wrap-chat {
    min-width: 155px;
    max-width: 300px;
    /* height: 100%; */
    border-radius: 14px;
    background-color: rgb(255, 255, 255);
    padding: 8px;
    border: 1px solid #0D0D0D1A
}

.header {
    margin-inline-start: 17px;
}

.wrap-chat .header .title {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    margin-inline-start: 5px;

}

.wrap-chat .header hr {
    margin: 0.2rem 0;
}

.message {
    /* height: 100%; */
    min-height: 150px;
    max-height: 100%

}

.message .profile-picture {
    min-width: 52px;
    max-width: 52px;
    min-height: 52px;
    max-height: 52px;
    margin-inline-end: 5px;
}

.message .profile-picture-img {
    width: 100%;
}

.message .text-bot {
    position: relative;
}

.message .text {
    /* background-color: #869cd7; */
    min-height: 70px;
    padding: 7px;
    margin: 1px;
    font-size: 11px;
    line-height: 1.2;
    border: none;
    border-radius: 6px ;
    background-color: #F4F7FF;
}

.message .text-bot .arrow {
    position: absolute;
    top: 0.5px;
    right: -9px;
}

.message .text-sender {
    position: relative;
    width: 100%;
}

.message .sender .profile-picture-sender {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    margin-inline-start: 5px;
    border-radius: 50%;
    background-image: url("../../../public/final.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
/* 
.message .sender .profile-picture-img-sender {
    width: 100%;
    border-radius: 50%;    
} */

.message .text-sender .arrow-left {
    position: absolute;
    top: 0.5px;
    left: -9px;
}

.message .text-sender textarea {
    background-color: #F4F7FF;
    font-size: 11px;
    border: none;
    resize: none;
    line-height: 1.2;
    width: 100%;
    padding: 0;
    min-height: 45px;
}

.message .text-sender .textarea:focus {
    outline: 0;
}

.message .text-sender .send {
    position: absolute;
    bottom: 2px;
    left: 5px;
}

.message .text-sender .send:hover {
    opacity: 0.6;
}


textarea::-webkit-scrollbar {
    width: 3px;
    direction:rtl;}

textarea::-webkit-scrollbar-thumb {
    background-color: #acb1dc; 
}

textarea::-webkit-scrollbar-track {
    background-color: #dddddf; 
}




/* .message .text-bot {
    position: relative;
    width: 100%;
}

.message .bot-textarea {
    width: 175px;
}


.message .picture-sender {
    border-radius: 35.879px;
    border: 0.5px solid rgba(255, 255, 255, 0.80);
    background-color: aqua;
    width: 32px;
    height: 35.879px;
    flex-shrink: 0;
} */

/* .message .text-sender {
    position: relative;
    max-width: 192px;
}

.message .sender-textarea {
    width: 160px;
}


.message .sender .img {
    width: 197px;
    height: 65.125px;
} */

