.card {
    width: 357px;
    height: 176px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.rightSection {
    width: 30px;
    background-color: hsl(206, 100%, 97%);
}

.numberClass {
    color: #AEC3D3;
    /* Set text color to #AEC3D3 */
    text-align: center;
    /* Align text to the center */
    font-family: 'Noto Sans Hebrew', sans-serif;
    /* Set font family */
    font-size: 24px;
    /* Set font size to 24px */
    font-style: normal;
    /* Set font style to normal */
    font-weight: 700;
    /* Set font weight to bold (700), adjust as needed */
    display: flex;
    /* Set display to flex */
    align-items: center;
    /* Center text vertically */
    justify-content: center;
    /* Center text horizontally */
    height: 100%;
    ;
}

.mainSection {
    flex-grow: 1;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
}

.row1 {
    height: 38px;
    /* Set the height of row1 to 38px */
    border-bottom: 1px solid #fff;
    overflow: hidden;
    /* Hide excess content */
    /* or overflow-y: auto; to add a scrollbar for excess content */
    line-height: 38px;
}

.titleClass {
    color: #000;
    /* Set text color to black */
    text-align: right;
    /* Align text to the right */
    font-family: 'Noto Sans Hebrew', sans-serif;
    /* Set font family */
    font-size: 15px;
    /* Set font size to 15px */
    font-style: normal;
    /* Set font style to normal */
    font-weight: 700;
    /* Set font weight to bold (700) */
    line-height: normal;
    /* Set line height to normal */
    padding: 12px;
    overflow: visible;

}


.row2 {
    margin-right: 11px;
    display: flex;
    height: 92px;
    padding: 10px 0 10px 0;
    justify-content: space-between;
}

.rightSection2 {
    width: 201px;
    /* Set fixed width for rightSection2 to 201px */
    /* Add any additional styling you need for rightSection2 here */
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.circle1 {
    background-color: #2B83C780;
    /* Color for the first circle */
}

.circle2 {
    background-color: #84C8FF80;
    /* Color for the second circle */
}

.circle3 {
    background-color: #D7D7D780;
    /* Color for the third circle */
}

.textContainer {
    display: flex;
    /* Use flexbox layout */
    align-items: center;
    /* Center circle and text vertically */

}


.textLine {
    flex-grow: 1;
    /* Allow the text lines to grow and fill the available height */
    display: flex;
    /* Use flexbox to align text lines */
    align-items: center;
    /* Center text lines vertically */

    margin-right: 11px;

}

.boldText {
    font-weight: bold;
    margin: 3px;
    /* Set font weight to bold for numeric characters */
}



.leftSection {
    width: 80px;
    padding-left: 12px;


}


.buttonStyle {
    background-color: #605899 !important;
    font-family: 'Roboto', sans-serif;
    height: 50%;
    padding-top: 8px;
    /* Add vertical padding */
    padding-bottom: 8px;
    /* Add vertical padding */

    /* Adjust the font size */
}

/* Optional: Add styling to row3 to ensure the button is centered or positioned as you like */
.row3 {
    display: flex;
    flex-wrap: 1;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
}

.DonutChart {
    width: 100%;
    /* this will ensure that the chart takes the full width of the left section */
    height: auto;
    /* adjust height as needed, could use percentage or fixed value */
    position: absolute;
    /* absolute positioning allows you to place the chart within the boundaries of the section */
    top: 0;
    /* adjust the positioning as needed */
    left: 0;
    /* adjust the positioning as needed */
}

.cards-wrapper{
    padding:0px 20px;
    display: flex;
    flex-direction: row;
    gap: 75px;
    flex-wrap: wrap;
}