.card {
    width: 357px;
    height: 176px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.rightSection {
    width: 30px;
    background-color: hsl(206, 100%, 97%);
}

.numberClass {
    color: #AEC3D3;
    /* Set text color to #AEC3D3 */
    text-align: center;
    /* Align text to the center */
    font-family: 'Noto Sans Hebrew', sans-serif;
    /* Set font family */
    font-size: 24px;
    /* Set font size to 24px */
    font-style: normal;
    /* Set font style to normal */
    font-weight: 700;
    /* Set font weight to bold (700), adjust as needed */
    display: flex;
    /* Set display to flex */
    align-items: center;
    /* Center text vertically */
    justify-content: center;
    /* Center text horizontally */
    height: 100%;
    ;
}

.mainSection {
    flex-grow: 1;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
}

.row1 {
    height: 38px;
    /* Set the height of row1 to 38px */
    border-bottom: 1px solid #fff;
    overflow: hidden;
    /* Hide excess content */
    /* or overflow-y: auto; to add a scrollbar for excess content */
    line-height: 38px;
}

.titleClass {
    color: #000;
    /* Set text color to black */
    text-align: right;
    /* Align text to the right */
    font-family: 'Noto Sans Hebrew', sans-serif;
    /* Set font family */
    font-size: 15px;
    /* Set font size to 15px */
    font-style: normal;
    /* Set font style to normal */
    font-weight: 700;
    /* Set font weight to bold (700) */
    line-height: normal;
    /* Set line height to normal */
    padding: 12px;
}


.row2 {
    margin:0px 11px 0px 7px;
    display: flex;
    height: 100%;
    padding: 10px 0 10px 0;
    justify-content: space-between;
}

.rightSection2 {
    width: 220px;
    /* Set fixed width for rightSection2 to 201px */
    /* Add any additional styling you need for rightSection2 here */
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.circle1 {
    background-color: #2B83C780;
    /* Color for the first circle */
}

.circle2 {
    background-color: #84C8FF80;
    /* Color for the second circle */
}

.circle3 {
    background-color: #D7D7D780;
    /* Color for the third circle */
}

.textContainer {
    display: flex;

    align-items: center;


}


.textLine {
    flex-grow: 1;
    font-size: 13px;
    /* Allow the text lines to grow and fill the available height */
    display: flex;
    /* Use flexbox to align text lines */
    align-items: center;
    /* Center text lines vertically */

    margin-right: 11px;

}

.textLine1 {
    flex-grow: 1;
    font-size: 13px;
    /* Allow the text lines to grow and fill the available height */
    display: flex;
    /* Use flexbox to align text lines */
    align-items: center;
    /* Center text lines vertically */

    margin-right: 6px;

}

.boldText {
    font-weight: bold;
    margin: 3px;
    /* Set font weight to bold for numeric characters */
}



.leftSection {
    width: 80px;
    /* Set fixed width for leftSection to 70px */

    /* Add a border to leftSection; adjust color and width as needed */
    /* Add any additional styling you need for leftSection here */
    margin-left: 10px;
}


.buttonStyle {
    background-color: #605899 !important;
    font-family: 'Roboto', sans-serif;
    height: 50%;
    padding-top: 8px;
    /* Add vertical padding */
    padding-bottom: 8px;
    /* Add vertical padding */
    font-size: 10px;
    /* Adjust the font size */
}

/* Optional: Add styling to row3 to ensure the button is centered or positioned as you like */
.row3 {
    display: flex;
    flex-wrap: 1;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
}

/* 
.DonutChart {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
} */

.plus-btn {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #4255A1;
    text-align: center;
    font-style: normal;
    line-height: normal;

}



.cardDesh {
    width: 300px;
    height: 70px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.leftSectionDesh {
    width: 40px;
    /* padding: 5px 5px 5px 0px; */
    margin-top: 5px;
        /* Set fixed width for leftSection to 70px */

    /* Add a border to leftSection; adjust color and width as needed */
    /* Add any additional styling you need for leftSection here */
    /* margin-left: 20px;
    margin-top: 15px; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto */
}

.rightSection2Desh {
    width: 220;

    column-gap: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Divide into two equal columns */
    grid-template-rows: 1fr 1fr
}

.titleClassDesh {
    color: #000;
    /* Set text color to black */
    text-align: right;
    /* Align text to the right */
    font-family: 'Noto Sans Hebrew', sans-serif;
    /* Set font family */
    font-size: 15px;
    /* Set font size to 15px */
    font-style: normal;
    /* Set font style to normal */
    font-weight: 700;
    /* Set font weight to bold (700) */
    line-height: normal;
    /* Set line height to normal */
    padding: 5px;
}

.textLineDesh {
    flex-grow: 1;
    font-size: 13px;
    /* Allow the text lines to grow and fill the available height */
    display: flex;
    /* Use flexbox to align text lines */
    align-items: right;
    /* Center text lines vertically */
    padding: 5px;


}