.update_personal .parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* Centers the child horizontally */
  gap: 25px;
  margin-top: 30px;
}

.update_personal .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  gap: 10px;
}

.update_personal .rows {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}

.update_personal .rows span {
  flex: 2;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
}

.update_personal .rows label {
  flex: 1;
  text-align: right;
}

.update_personal .rows div {
  flex: 4;
  text-align: right;
}

.update_personal input {
  border: 0px;
}
.hover-effect {
  background-color: rgba(224, 224, 224, 0.4); /* or any default color */
  cursor: pointer;
  border-radius: 10px; /* Optional: Changes the cursor to indicate it's interactive */
}

/* Hover state */
.hover-effect:hover {
  background-color: rgb(
    224,
    224,
    224
  ); /* This is the color it will change to on hover */
}
