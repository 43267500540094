

body {
    background-color: rgb(215, 227, 248);
    font-family: "Noto Sans Hebrew";
}


.layout {
    

    background:  linear-gradient(180deg, #E9F2FF 11.98%, #DAF6FF 100%);

    /* background-color: rgb(215, 227, 248); */
    min-height: 100vh;
}

a {
    padding: 15px 10px;
}

.err {
    color: red;
    font-size: x-small;
}
