/* Apply some general styling to the table */
.scheduler table {
  border-collapse: collapse;
  width: 100%;
  max-width: 900px;
  /* padding-bottom: 10px; */
  /* Set a maximum width for the table */
  margin: 20px auto;
  /* Center the table on the page */
}

/* Style for table header (th) */
.scheduler th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

/* Alternating row colors for better readability */
/* .scheduler tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* Style for table cells (td) */
.scheduler td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  color: #000;
}

.scheduler td button {
  color: white;
}

/* Highlight cells on hover */
.scheduler tr:hover td {
  background-color: #e0e0e0;
}

/* Add spacing to the first and last columns */
.scheduler td:first-child,
th:first-child {
  padding-left: 15px;
}

.scheduler td:last-child,
th:last-child {
  padding-right: 15px;
}

.fw-bold {
  font-weight: bold;
}

.delete-btn {
  transition: transform 0.2s ease-in-out;
  /* Smooth transition for the transform property */
}

.delete-btn:hover {
  transform: translateX(-5px);
  /* Moves the button slightly to the left on hover */
}

.scheduler table tr {
  max-height: 50px;
  /* You can adjust the value as needed */
}

.px-5 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  border-radius: 15px;
}