.bg-blur {
  /* The image used */
  /* background-image: url("photographer.jpg"); */

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Position text in the middle of the page/image */
.bg-text {
  background-color: rgb(255, 255, 255);
  /* Fallback color */
  background-color: rgba(255, 255, 255, 0.4);
  /* Black w/opacity/see-through */
  color: rgb(32, 31, 31);

  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 30%;
  padding: 20px;
  text-align: center;
}

.lightbox-container {
  position: relative;
  /* ... other styles ... */
}

.lightbox-logo {

  position: absolute;
  top: -25px;

  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  padding-left: 2px;
  height: 70px;



  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  border-radius: 35px;

}