
.wrap {
    /* width: 246px; */
    /* height: 287px; */
    max-width: 300px;
    border-radius: 14px;
    background-color: rgb(255, 255, 255);
    padding: 7px;
    border: 1px solid #0D0D0D1A
}

.wrap .title {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    margin-inline-start: 5px;

}

.wrap hr {
    margin: 0.5rem 0;
}

.item {
    border-radius: 30px;
    background: rgba(205, 166, 166, 0.09);
    box-shadow: 1px 1px rgb(225, 224, 224);
    display: flex;
    width: 230px;
    padding: 2px 1px;
    margin-top: 8px ;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.item .number {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.item .text {
    color: rgba(0, 0, 0, 0.70);
    text-align: right;
    font-size: 10px;
    font-style: normal;
    /* font-weight: 300; */
    line-height: 109.5%;
}