.app-container {
    display: flex;

    /* full viewport height */
}

.layout {
    flex-grow: 1;
    /* layout takes remaining space */
    /* your existing layout styles */
}