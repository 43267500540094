.search-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.date-picker {
  display: flex;
  gap: 10px
}

/* .date-picker-wrapper{
  position: relative;
} */

.date-picker-wrapper .react-datepicker-popper {
  z-index: 10000;
}

.title {
  color: #0D0D0D;
  text-align: right;
  /* font-family: Noto Sans Hebrew; */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



/* Table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;

}

.custom-table .date-picker-wrapper {
  overflow: auto !important;
  /* or any other desired value */
}


/* Header row styling */
.custom-table thead {
  font-size: 15px;
  color: black;
  text-align: center;
  position: sticky;
  top: 0;
  /* Set the top value as needed */
  z-index: 2;
  /* Ensure the z-index is higher than other elements */
  background-color: #fff;
  /* Add a background color to distinguish it from the rest of the table */
}

.custom-table tr {
  margin: 0px 10px;
}

tr .sub_header {
  font-size: 12px;
}

.date-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid #FFF; */
}

.day_now {
  border-radius: 14px !important;
  border: 1px solid #54B5EC;
}

.custom-table th {
  padding: 10px;
  border: none;

  /* width: 150px; */
}

.custom-table .first-cube {
  border-radius: 14px;
  background: #F3F3F3;
  /* padding: 5px; */
  /* height: 56px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  height: 50px;
}

.custom-table td:not(:first-child),
.custom-table th:not(:first-child) {
  width: 15%;
  /* Adjust the width as needed */
  padding: 2px 0px;
}

.custom-table td:first-child,
.custom-table th:first-child {
  width: 5%;
  padding: 3px 0px;
}

/* Hover effect on table rows */
.custom-table tbody tr:hover {
  /* background-color: #ddd; */
}

.custom-table tr {
  height: 55px;
}

.cube-wrapper {
  padding: 0px 10px;
  /* max-width: 125px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cube {
  border-radius: 14px;
  background: white;
  display: flex;
  flex-direction: column;
  display: flex;
  /* height: 52px; */
  justify-content: start;
  /* align-items: flex-end; */
  gap: 6px;
  flex-shrink: 0;
  padding: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  max-width: 165px;
  width: 100%;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
}

.outLesson {
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.className {
  color: #0D0D0D;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
}

.knowledge {
  color: #0D0D0D;
  text-align: right;
  /* גודל 10 דק */
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.week-table-wrapper {
  position: relative;
  border-radius: 14px;
  border: 1px solid #FBFDFF;
  background: #FFF;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.10);
  padding: 25px 40px;
  width: 100%;
  /* max-height: 650px; */
  /* Fixed height */
  /* overflow-y: auto; */
}

.react-datepicker-wrapper input {
  color: #0D0D0D;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.control-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  /* Space between components */
}