.custom-input {
    padding: .375rem .75rem .375rem .75rem;
    border: 1px solid #ccc;
    border-radius: 15px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    width: 85px;
  }
  
  .custom-input::placeholder {
    color: #999;
  }
  
  .custom-input:focus {
    border-color: dodgerblue;
  }

  .input-label {
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; Add margin to separate each label-input pair */
  }

  .select-custom{
    min-width: 170px;
}
  

  