.wrap-study-program {
    position: relative;
    /* height: 659px; */
    width: 100%;

    padding: 12px;
    background-color: #ffffff;
}

.top-banner {
    margin: 10px;
}

.summary {
    height: 24px;
    width: 440px;
    font-size: 12px;
}

.explanation {
    height: 38px;
    width: 377px;
    font-size: x-small;
    margin-inline-end: 43px;
    text-align: left;
}

.lessons {
    padding: 20px;
}

.lesson {
    margin: 20px;
    height: 143px;
    width: 248px;
    border-radius: 12px 12px 12px 12px;
    box-shadow: 0.8548016548156738px 0.8548016548156738px 8.548016548156738px 0px #0000001A;
}


.lesson .details {
    padding: 10px;
    width: 222.87px;
    height: 143px;
    left: 732.68px;
    border: 2px solid #ECF1FF;
    border-radius: 12px 0px 0px 12px;
}

.lesson .details-content {
    height: 100%;
}

.lesson .number {
    width: 25px;
    height: 143px;
    /* left: 955.56px; */
    border-radius: 0px 12px 12px 0px;
}

.basic-lesson-color {
    background-color: #EFF8FF
}

.enrichment-lesson-color {
    background-color: #F5F2F2
}

.lesson .title {
    color: #4B4D4F;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    width: 196px;
}

.lesson .div-input-checkbox .container-inp {
    height: 20px;
    display: flex;
    align-items: baseline;
    position: relative;
    padding-right: 25px;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 22px;
}

.lesson .div-input-checkbox input {
    position: absolute;

    top: 2px;
    right: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.lesson .div-input-checkbox .checkmark {
    position: absolute;
    top: 2px;
    right: 0;
    height: 16px;
    width: 16px;
    text-align: right;
    border: 1px solid #858D9D;
    border-radius: 6px;

}

.lesson .icon {
    position: absolute;
}

.lesson .div-input-checkbox .check {
    background-color: #C8EB3E;
    border: 1px solid #C8EB3E;
}

.lesson .create-btn {
    width: 163.63px;
    height: 23px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    width: 163.63px;
    height: 23px;
    border: 0.1px solid #BEBEBE;
    border-radius: 9px;
    background-color: #BEBEBE;
    /* background: linear-gradient(0deg, #847D7D, #847D7D),
    linear-gradient(0deg, #BEBEBE, #BEBEBE); */
}

.lesson .btn-color-purple {
    background-color: #605899
}

.lesoon .btn-color-gray {
    background-color: #847D7D;
}

.type-lesson {
    width: 196.37px;
    height: 22.7px;
    top: 7.16px;
    left: 747.68px;

    color: #4B4D4F;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 200;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;

}

.plus-in-card {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
}

.enrichment .add-lesson {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline-end: 43px;
}

.enrichment {
    position: relative;
}

.enrichment .add-lesson:hover {
    color: #858D9D;
}


/* .lesson .inp {
    width: 16px;
    height: 16px;
    border-radius: 6px;

    font-family: sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
} */


.left-color {
    background-color: rgb(233, 243, 251);
}

.grabbable {
    flex: 0 !important;

}