.background {
    background: linear-gradient(180deg, #E9F2FF 11.98%, #DAF6FF 100%);
}

.login-box {
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.51);
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 45px;
}

.login-logo-box {
    width: 400px;
    display: flex;
    flex-direction: column;
    max-height: 450px;
    height: 100%;
    justify-content: space-between;
}

.btn-primary {
    background-color: #3C2F7F;
    border-color: #3C2F7F;
}

.btn-primary:hover {
    color: #fbfbfc;
    background-color: #3C2F7F;
    border-color: #3C2F7F
}

.input-login {

    Input {
        max-height: 40px;
    }
}