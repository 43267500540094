.dashboard-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90vh;
    padding: 20px 0px 30px 0px;
}

.dashboard-main {
    padding: 0px 70px 0px 0px; 
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    /* padding-bottom: 30px; */
    max-height: 100vh;
}

.classSum-wrapper {
    height: 25vh;
    overflow: auto;
    background-color: #FFFFFF;
    border-radius: 14px
}

.calendar-overflow {
    height: 60vh;
    overflow: auto;
}

.left-side {
    /* max-height: 100vh; */
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;

    /* height: 100%; */
    .taskContainer {
        margin-right: 0px;
    }

    /* width: 20%; */
}

.chat {
    /* margin-top: auto; */
    /* padding-bottom: 30px; */

}

.right-side {
    width: 250px;
    /* Adjust the width of the sidebar */
    /* other styles */
}

.sidebar-custom {
    transition: width 0.3s;
    /* Smooth transition for width change */
    /* Additional styles */
}