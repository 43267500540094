/*frontend-teach\src\components\Lesson\Grid.css*/
.grid {
    display: grid;

    row-gap: 20px;

    margin: 0 auto;

    padding: 0 35px 0 0;
    /* adjusted padding */
}


@media (max-width: 480px) {
    .grid {
        grid-gap: 30px 45px;
    }
}