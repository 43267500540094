header {
  /* background-color: rgb(122, 178, 233); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.circle-image {
  padding: 20px;



}

.profile-container {
  display: flex;
  align-items: center;
}



.text-details {
  display: flex;
  flex-direction: column;
}