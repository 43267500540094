#main {
    /* position: relative; */
    /* Create a positioning context for the absolute element */


}


#main img {

    /* position: absolute; */
    /* Position the image absolutely */
    /* top: -40px; */
    /* Adjust the top position as needed */
    /* left: 600px; */
    /* z-index: 9999; */
    /* Adjust the left position as needed */
}

.lightbox-wrapper{
    box-shadow: 0px 0px 60px 7px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    background: #FFF;
    filter: drop-shadow(0px 1px 0.5px #555A66);

}