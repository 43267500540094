/*frontend-teach\src\Task\Tasks.css*/

.taskContainer {

    padding: 20px;
    /* width: 300px; */
    min-height: 300px;
    margin-right: 300px;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 20px 0px #0000001A;
    /* border: 2px solid black; */
    border-radius: 14px;
    position: relative;
    /* This is added to position the element according to the top and left properties */
    display: grid;
    grid-template-rows: repeat(30, 12.5);


    row-gap: 8px;




}


.item-1 {
    grid-row: span 2;
}


.item-2 {
    grid-row: span 4;
}



.item-4 {
    grid-row: span 4;
}



.img {
    width: 20px;
    height: 20.163px;
    flex-shrink: 0;
    margin-right: 19px;
}

.title-1 {
    margin-right: 4px;
    font-weight: 700;
    font-size: 16px;
}

.title-2 {
    margin-right: 15px;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;

}

/* .title {} */

/* Tasks.css */

.checkbox {
    margin-right: 15px;
    margin-left: 10px;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #576464;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0;
    position: relative;
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.25);
}

.checkbox:checked:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    background-color: #99a1a7;
    border-radius: 50%;
}

.add-todo-form {
    margin-right: 35px;
    display: flex;
    gap: 10px;
    align-items: center
}

.todotext {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides text that overflows the element's box */
    text-overflow: ellipsis;
}

.tododiv {
    margin-top: 5px;

    white-space: nowrap;

}

.Addtodo {
    margin-top: 5px;
}

.add-todo-text {
    color: rgb(61, 61, 186);
    font-weight: 650;
    margin-right: 20px;
}

.add-todo-button2 {

    margin-right: 40px;
}

.add-todo-input {
    border-radius: 12px;
    min-width: 210px;

}

.add-todo-input::placeholder {
    color: rgb(61, 61, 186);
    font-weight: 650;

}


.line {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.popup {
    position: absolute;
    top: 230px;

    transform: translate(-50%, -50%);
    background: #fff;

    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.add-todo-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.add-todo-button,
.close-button {
    border-radius: 12px;
    width: 80px;
    height: 30px;
    margin-top: 10px;
    color: white;
    background-color: #3d3dba;
    /* matching the add-todo-text color */
    border: none;
    cursor: pointer;
}

.popup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255, 0.8);
}

.textarea-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.taskContainer2 {


    width: 300px;
    min-height: 220px;
    margin-right: 300px;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 20px 0px #0000001A;
    /* border: 2px solid black; */
    border-radius: 14px;
    position: relative;
    /* This is added to position the element according to the top and left properties */
    display: grid;
    grid-template-rows: repeat(30, 12.5);


    row-gap: 8px;




}