.cube-details td:first-child,
th:first-child {
    padding-left: 1px;
}

.cube-details .col {
    flex: 0 1 280px
}

.div-ellipsis p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.cube_scroll::-webkit-scrollbar {
    display: none;
  }

  .cube-details{
    direction: rtl;
    padding: 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  }
  .cube-details{
    
    /* tr{
        height: 26px;
    } */
}


.swiper {
  width: 91vw;

  /* height: 570px; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


