
.wrap-overview {
    width: 100%;
    border-radius: 14px;
    padding: 35px;
    box-shadow: 1px 1px 20px 0px #0000001A;
    background-color: rgb(255, 255, 255);

}

.wrap-overview .plus-btn {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #4255A1;
    text-align: center;
    font-style: normal;
    line-height: normal;

}

.wrap-overview .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: right;
}

.wrap-overview .expand-view {
    color: #4255A1;
    font-size: 10px;
    font-weight: 300;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: right;
    margin-inline-start: 15px;
    
}

.item {
    position: relative;
    width: 259px;
    height: 69px;
    border-radius: 15px;
    background-color: rgba(212, 243.98, 251, 0.4);
    padding: 10px;
    font-size: 12px;
    box-shadow:1px 1px #e0dede;
}

.item .title {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;

}

.item b {
    margin-inline-end: 5px;
}

.item .chart {
    width: 39px;
    height: 39px;
}

.item .icon-brush {
    position: absolute;
    top: 2px;
    left: 5px;
}